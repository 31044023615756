/**
 * Colors
 */
$colors: (
    'black'             : #000000,
    'dark-grey'         : #9f9f9e,
    'light-grey'        : #edede7,
    'dirty-white'       : #f4f4f5,
    'dark-red'          : #AE2A24,
    'red'               : #F32836,
    'white'             : #ffffff,
);

/**
 * Color applications
 */
$black-text-color              : map-get($colors, 'black');
$dark-text-color               : map-get($colors, 'dark-grey');
$light-text-color               : map-get($colors, 'light-grey');
$dirty-text-color               : map-get($colors, 'dirty-white');
$white-text-color               : map-get($colors, 'white');

$highlight-dark-red-color       : map-get($colors, 'dark-red');
$highlight-red-color            : map-get($colors, 'red');


/* backgrounds */
$black-bg-color                 : map-get($colors, 'black');
$dark-bg-color                  : map-get($colors, 'dark-grey');
$light-bg-color                 : map-get($colors, 'light-grey');
$dirty-bg-color                 : map-get($colors, 'dirty-white');
$white-bg-color                 : map-get($colors, 'white');

/* borders */
$black-border-color              : map-get($colors, 'black');
$dark-border-color              : map-get($colors, 'grey');
$white-border-color             : map-get($colors, 'white');
$light-border-color             : map-get($colors, 'light-grey');
$dirty-border-color             : map-get($colors, 'dirty-white');


/**
 * Fonts
 */
$font               : 'Catamaran', sans-serif;
$icon-font          : 'Material-Design-Iconic-Font', sans-serif;

$font-weight-thin   : 100;
$font-weight-regular: 400;

 /**
  * Icons
  */
 $icons: (
    /* private area */
    menu            : '\f197',
    quote           : '\f1b2',
    close           : '\f134',

    /* arrows */
    arrow-left      : '\f2ff',
    arrow-right     : '\f301',
    arrow-down      : '\f2fe',
    arrow-right-up  : '\f2ed',
    arrow-caret-right: '\f2f6',
    down            : '\f2f2',

    /* miscellaneous */
    user        : '\f206',
    pin         : '\f1AB',
    check       : '\f26b',
    alert       : '\f1f0',
    plus        : '\f278',
    download    : '\f220',


    /* social media */
    facebook        : '\f343',
    twitter         : '\f360',
    share           : '\f35b',
 );
