.article{

    &__content{

        &:nth-of-type(n+1){
            margin-top: 30px;
        }
    }

    .section__header{

        @include breakpoint(tp){
            width: 50%;
        }
        h2{
            color: $black-text-color;
        }
    }

    &__text{
        @include breakpoint(tp){
            padding-left: 20px;
        }
        h4{
            color: $dark-text-color;
        }
    }

    &__image{
        width: 100%;
        height: 100%;
        @extend .bg-cover;
    }

    &__image-wrap{
        height: 300px;

        &:nth-of-type(n+2){
            margin-top: 20px;
        }

        @include breakpoint(tp){

            &:nth-of-type(n+2){
                margin-top: 0;
            }

            &:nth-of-type(2n-1){
                padding-right: 20px;
            }
            &:nth-of-type(2n){
                padding-left: 20px;
            }
        }
    }
}

.detail{
    &__description{

    }
    &__list{
        padding-top: 20px;

        @include breakpoint(tp){
            padding-top: 0;
            padding-left: 25px;
        }

        li{
            margin-bottom: 10px;

            p{
                text-transform: uppercase;
                color: $highlight-dark-red-color;
            }
        }
    }
}
