.testemonials{
    border: 1px solid $light-border-color;
    padding:  20px 50px;
    text-align: center;

    &__title{
        transform: translateY(-45px);
        line-height: 40px;
    }

    &__text{
        color: $dark-text-color;
    }

    &__name{
        color: $black-text-color;
        text-transform: uppercase;
        margin-top: 25px;
    }
    &__proj{
        color: $black-text-color;
        margin-top: 5px;
    }
}
