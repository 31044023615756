label{
    cursor: pointer;
}

.form{

    input[type=email],
    input[type=text],
    input[type=password],
    textarea{
        margin-bottom: 10px;
        color: $text-color;
        padding: 5px 20px;
        font-family: $font;
        box-sizing: border-box;
        color: $black-text-color;
    }

    input{
        height: 50px;
    }

    .col-pl-6{

        @include breakpoint(pl){


            &:nth-of-type(2n-1){
                padding-right:5px;
            }
            &:nth-of-type(2n){
                padding-left:5px;
            }
        }
    }

    //CONTACTS PAGE
    &--contacts{

        input[type=email],
        input[type=text],
        textarea{
            background-color: $white-bg-color;
        }
    }

    //PROFILE PAGE
    &--user{
      width: 100%;

      @include breakpoint(tp){
        width: 50%;
        margin: auto;
      }
    }

    &--user,
    &--light{

        input[type=text],
        input[type=password],{
            background-color: $light-bg-color;
        }

    }

    input:focus, textarea:focus {
        color: $text-color;
    }

    .tip{
        font-size: 18px;
    }

    /* op results */
    .op_result{
        p{
            margin-bottom: 1.5em;
        }
    }
}

.newsletter{
    input{
        background-color: $white-bg-color;
        height: 50px;
        width: 100%;
        color: $text-color;
        padding: 5px 20px;
        box-sizing: border-box;
    }

    &__btn{
        margin-top: 10px;
        &:hover{
            color: $highlight-dark-red-color;
        }
    }
}
