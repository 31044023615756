/**
 * Headings
 */
h2.title{
	font-family: $font;
	font-size: 40px;
	font-weight: $font-weight-thin;
	letter-spacing: 1px;
	text-transform: lowercase;
	color: $highlight-dark-red-color;

	@include breakpoint(tp){
		font-size: 60px;
	}
}

h3{
	font-family: $font;
	font-size: 25px;
	font-weight: $font-weight-regular;
	letter-spacing: 1px;
	text-transform: uppercase;
	line-height: 30px;
	color: $black-text-color;
}

h4{
	font-family: $font;
	font-size:20px;
	font-weight: $font-weight-regular;
	letter-spacing: 1px;
	line-height: 24px;
	color: $black-text-color;
}

p{
	font-size: 16px;
	font-family: $font;
	font-weight: $font-weight-regular;
	letter-spacing: 1px;
	line-height: 20px;
	span{
		text-transform: uppercase;
	}
}

/**
 * Editable contents
 */
.editable{

	p + h4{
		margin-top: 1.5em;
	}
	img{
		max-width: 100% !important;
		height: auto !important;
		margin: 2.5em auto !important;
		display: block !important;

		@include breakpoint(tl){
			max-width: 80% !important;
		}
	}
}
