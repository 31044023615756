.call-to-action{
    width: 100%;
    display: table;
    cursor: pointer;

    &__content{
        padding: 1em;
        display: block;
        text-align: center;
        font-family: $font;
        font-size: 25px;
        text-transform: uppercase;

        @include breakpoint(tp){
            display: table-cell;
            vertical-align: middle;
            padding: 1.5em;
            font-size: 1.6em;
        }
        @include breakpoint(tl){
            font-size: 2.2em;
        }

        &:first-child{
            background-color: $dirty-bg-color;
            color: $highlight-dark-red-color;


            /* hover */
            &:hover{
                background-color: $highlight-dark-red-color;
                color: $dirty-text-color;
            }
        }
        &:last-child{
            background-color: $highlight-dark-red-color;
            color: $white-text-color;

            /* hover */
            &:hover{
                background-color: $dirty-bg-color;
                color:  $highlight-dark-red-color;

            }
        }

    }
}
