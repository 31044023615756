.card-list{
  display: none;
    margin: 3em 0;

    @include breakpoint(pl){
      display: block;
    }

    &__element{

        &:nth-of-type(n+2){
            margin-top: 30px;
        }

        @include breakpoint(tp){
          padding: 0 20px;

            &:nth-of-type(n+2){
                margin-top: 0;
            }
        }

        @include breakpoint(tl){
            padding: 0 10px;
        }

        @include breakpoint(dt){
            padding: 0 30px;
        }

    }
}

.services-list{

    .section__header{

        @include breakpoint(tp){
            width: 100%;
        }
    }

    &__element{
        height: auto;

        @include breakpoint(tp){
            height: 282px;
        }

        @include breakpoint(tl){
            height: 222px;
        }
    }
}

.models-list{
    padding-top:30px;

    &__arrows{
        @include breakpoint(tp){
            display: none;
        }
    }
    &__element{

        &:nth-of-type(n+2){
            margin-top: 30px;
        }

        @include breakpoint(tp){

            &:nth-of-type(n+2){
                margin-top: 0;
            }

            &:nth-of-type(3n-2){
                padding-right: 30px;
            }

            &:nth-of-type(3n-1){
                padding-left: 15px;
                padding-right: 15px;
            }

            &:nth-of-type(3n){
                padding-left: 30px;
            }
            &:nth-of-type(n+4){
                margin-top: 30px;
            }

        }

        @include breakpoint(tl){
            min-height: 200px;

            &:nth-of-type(n+4){
                margin-top: 50px;
            }

            &:nth-of-type(3n-2){
                padding-right: 50px;
            }

            &:nth-of-type(3n-1){
                padding-left: 25px;
                padding-right: 25px;
            }

            &:nth-of-type(3n){
                padding-left: 50px;
            }
        }
    }
}


.projects-list{

    &__element{
        height: 400px!important;
        cursor: pointer;

        &:nth-of-type(n+2){
            margin-top: 30px;
        }

        @include breakpoint(tp){
            &:nth-child(4n+2), &:nth-child(4n+3){
                padding: 0 70px 70px;
            }

        }

        @include breakpoint(tp){

            &:nth-of-type(n+2){
                margin-top: 0;
            }
            &:nth-of-type(n+3){
                margin-top: 50px;
            }
        }

        &--big{

        }
        &--small{
            padding: 0;

            @include breakpoint(tp){
                padding: 0 70px 70px;
            }
        }
    }
}

.partners{
    &__element{

        &:nth-of-type(n+2){
            margin-top: 30px;
        }

        @include breakpoint(tp){

            &:nth-of-type(n+2){
                margin-top: 0;
            }

            &:nth-of-type(n+4){
                margin-top: 50px;
            }

            &:nth-of-type(3n-2){
                padding-right: 50px;
            }

            &:nth-of-type(3n-1){
                padding-left: 25px;
                padding-right: 25px;
            }

            &:nth-of-type(3n){
                padding-left: 50px;
            }
        }
    }
}

.docs-list{
    li{
        height: 60px;
        margin-bottom: 15px;
        border-bottom: 2px solid $dirty-border-color;

        @include breakpoint(tp){
            height: 40px;
        }
        a{
            color: $highlight-dark-red-color;
            &:hover{
                color: $text-color;
            }
        }
    }
}
