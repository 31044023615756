body{
    background-color: $white-bg-color;

    *{
        letter-spacing: .06em;
    }
}


/**
 * Section
 */
.section{
    padding-bottom: 4.5em;

    &__header{
        padding-top: 0;
        line-height: 4em;

        @include breakpoint(tp){
            width: 65%;
        }

        hr{
            height: 2px;
            width: 100px;
            float:left;
            background-color: $highlight-dark-red-color;
            margin-top: 3px;
        }

        &--center{
            text-align: center;
            width: 100%;

            hr{
                float: none;
            }
        }

    }

    &__content{
        padding-bottom: 0;
        padding-top: 1.5em;
    }
}


/**
 * Background colors
 */
.light-bg-color{
    background-color: $light-bg-color;
}
.dirty-bg-color{
    background-color: $dirty-bg-color;
}
.red-bg-color{
    background-color: $highlight-red-color;
}

/**
 * Page nav
 */
.page-nav{
    margin-top: 4.5em;
    border-color: $light-border-color;
    border-style: solid;
    border-width: 1px 0;

    /* social column */
    &__social-col{
        padding-top: 2em;
        clear: both;

        @include breakpoint(tl){
            padding-top: 0;
            clear: none;
        }
    }

	/* arrows */
	.icon{
		line-height: 46px;

        span{
            display: none;

            @include breakpoint(tp){
                display: inline;
            }
        }

		&--arrow-up,
		&--arrow-left{
			&::before{
				margin-right: .6em;
			}
		}
		&--arrow-right{
			margin-left: 1em;

			&::before{
				margin-left: .6em;
				float: right;
			}
		}

	}


}

/**
 * User page
 */

.user{

    &__content{
        margin-top: 20px;

        @include breakpoint(tp){
            padding-left: 50px;
            margin-top: 0;
        }
    }

}



/**
 * Page footer
 */
.page-footer{
    /* top bar */
    &__top{
        padding: 3em 0;

        h3{
            color: $text-color;
            margin-bottom: 15px;
            text-transform: lowercase;
        }
    }

    /* columns */
    &__newsletter-col,
    &__contacts-col,
    &__links-col{

        @include breakpoint(pp, tl){
            margin-bottom: 1em;
            text-align: center;
        }
    }
    &__contacts-col{

        @include breakpoint(tp){
            text-align: left;
            padding-right: 3em;
        }
    }
    &__newsletter-col{
        padding-top: 2em;

        @include breakpoint(tp){
            padding-top: 0;
        }

        /* social networks */
    	.btn-network{
    		margin-top:30px;
    	}
    }
    &__links-col{
        padding-top: 2em;

        @include breakpoint(tp){
            padding-top: 0;
            padding-left: 3em;
            text-align: right;
        }

        li:hover{
            a{
                color: $highlight-dark-red-color;
            }
        }
    }


    &__bottom{
        background-color: $white-bg-color;

        /* credits */
        .credits{
            padding-top: .5em;
            padding-bottom: .5em;
            line-height: 50px;
            color: $text-color;
            h6{
                font-weight: $font-weight-thin;
                a:hover{
                    color: $highlight-dark-red-color;
                }
            }
        }
    }
}
