
/**
 * Button
 */
.btn{
    display: block;
    height: 50px;
    width: 280px;
    line-height: 45px;
    text-align: center;
    color: $text-color;
    font-size: 18px;
    letter-spacing: 1px;
    font-family: $font;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    background-color: transparent;
    padding-right: 10px;
    padding-left: 10px;
    border: 2px solid $dirty-border-color;
    border-radius: 50px;
    box-sizing: border-box;
    transition: background-color .5s;
    &:hover,
    &:focus{
        border-color: $highlight-red-color;
        color: $highlight-red-color;
    }


    /* center */
    &--center{
        margin: 30px auto;
        text-align: center;
        display: block;
    }
    /* responsive */
    &--responsive{
        border: none;

        &:hover,
        &:focus{
            color: $text-color;
        }

    }
}


/**
 * Links
 */
.link-btn{
    display: inline-block;
    height: 40px;
    position: relative;
    cursor: pointer;
	font-family: $font;
	font-size: 16px;
    line-height: 40px;
	letter-spacing: 0.5px;
    color: $highlight-dark-red-color;
	font-weight: $font-weight-regular;
    transition-property: color;
    transition-duration: .5s;

    .icon{
        margin-left: 10px;
        margin-right: 10px;
    }

    &:hover{
        color: $dark-text-color;
    }

    &--grey{
        color: $dark-text-color;
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}

/**
 * Scroll button
 */
.scroll-btn{
    position: relative;
    color: $highlight-red-color;
    font-size: 26px;
    box-sizing: border-box;
    cursor: pointer;


    /* scroll down */
    &--down{
        height: 50px;
        width: 50px;
        color: $highlight-dark-red-color;
        font-size: 24px;
        &::before{
            @include icon(map-get($icons, arrow-down));
        }

        &:hover,
        &:focus{
            color: $dark-text-color;
        }
    }
}

/**
 * Arrow buttons
 */
.btn-arrow{
    width: 70px;
    height: 70px;
    line-height:66px;
    font-size: 20px;
    background-color: transparent;
    display: inline-block;
    transition: background-color .5s;

    /* left */
    &--left{
        float: left;
        &::before{
            @include icon(map-get($icons, arrow-left));
        }
    }

    /* right */
    &--right{
        float:right;
        &::before{
            @include icon(map-get($icons, arrow-right));
        }
    }

}


/**
 * Networks
 */
.btn-network{
    @include make-circle(40);
    font-size: 22px;
    background-color: transparent;
    border: 2px solid $white-border-color;
    color: $text-color;
    box-sizing: border-box;
    transition-property: color, background-color;
    transition-duration: .5s;
    display: block;

    span{
        display: none;
    }

    &--facebook{
        &::before{
            @include icon(map-get($icons, facebook));
        }
    }

    /* hover */
    &:hover{
        color: $highlight-dark-red-color;
        background-color: transparent;
        border: 2px solid $highlight-dark-red-color;
    }
}
