.banner-wrapper{
    position: relative;

    &--top{
      height: 100vh;
    }


}


.banner{
    height: 300px;

    @include breakpoint(pl){
        height: 380px;
    }
    @include breakpoint(tp){
        height: 420px;
    }
    @include breakpoint(tl){
        height: 450px;
    }
    @include breakpoint(dt){
        height: 480px;
    }

    &--top{
        position: relative;
        height: 100%;


        @include breakpoint(tp){
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 0;
        }
    }

    &__cell{
      display: table-cell;
      vertical-align: middle;
      max-height: 300px;
      overflow: hidden;

        @include breakpoint(tp){
          vertical-align: bottom;
        }
        .title{
            margin-bottom: 40px;

            @include breakpoint(tp){
                margin-bottom: 20%;
            }
        }
    }

    &__content{
        @extend .bg-cover;
        box-sizing: border-box;
        margin: center;

        @include breakpoint(tp){
            padding-top:0;
        }
    }
}
