/* base styles (import before fw files!) */
@import "app/base/config.scss";

/* vendor */
@import "vendor/fw/fw.scss";

/* layout */
@import url('https://fonts.googleapis.com/css?family=Catamaran:100,400');
@import "app/layout/layout.scss";
@import "app/layout/typography.scss";

/* components */
@import "app/components/navbar.scss";
@import "app/components/buttons.scss";
@import "app/components/banner.scss";
@import "app/components/cards.scss";
@import "app/components/lists.scss";
@import "app/components/form.scss";
@import "app/components/articles.scss";
@import "app/components/slideshow.scss";
@import "app/components/contacts.scss";

/* partials */
@import "app/partials/testemonials.scss";
@import "app/partials/call-to-action.scss";
