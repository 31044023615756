.contacts{

    .title{
        color: $black-text-color;
        line-height: 1;
    }
    &__form{
        margin-top: 20px;

        @include breakpoint(tp){
            margin-top: 0;
            padding-left: 60px;
        }
    }

    &__address{
        background-color: $light-bg-color;
        padding: 20px;
        height: auto;

        @include breakpoint(tp){
            height: 320px;
        }
        @include breakpoint(tl){
            height: 460px;
        }

        .title{
            margin: 10% 0;
            line-height: 1;


            @include breakpoint(tl){
                margin-bottom: 70px;
            }
        }
    }

    &__map{
        height: 320px;
        margin-top: 20px;

        @include breakpoint(tp){
            margin-top: 0;
        }

        @include breakpoint(tl){
            height: 460px;
            padding-left: 60px;
        }
    }
}
#map{
    height: 100%;
    width: 100%;
}
