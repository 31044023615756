


.navbar{
    position: relative;

    &--absolute{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;

        .navbar__menu-toggler{
            &:hover{
                &::before{
                    color: $white-text-color;
                }
            }
        }
    }

    /* logo */
    &__logo{
        padding-top: 4px;
        padding-bottom: 4px;
        vertical-align: top;
    }

    /* menu */
    &__menu{
        position: relative;
    }

    /**
     * Button Menu
     */
    &__menu-toggler{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50px;
        height: 90px;
        z-index: 10;

        button{
            width:50px;

            &::before{
                font-size: 30px;
                line-height: 90px;
                color: $highlight-red-color;
            }
            &:hover{
                &::before{
                    color: $black-text-color;
                }
            }
        }

    }
}

/**
* Menu Full Screen
*/
.menu{
    display: none;
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px 10px;
    background-image: url('/images/photos/Homepage/photo2.jpg');
    @extend .bg-cover;
    z-index: 2;

    @include breakpoint(tp){
        padding: 30px;
    }
    &--active{
        display: block;
    }

    &__submenu{

        @include breakpoint(tp){
            line-height: 90px;
            margin-bottom: 100px;
        }

    }

    &__links{
        margin-top: 20px;

        @include breakpoint(tp){
            margin-top: 0;
        }

        ul{
            li{
                a{
                    display: inline-block;
                    text-transform: lowercase;
                    font-size: 40px;
                    line-height: 45px;
                    font-weight: $font-weight-thin;
                    color: $highlight-red-color;

                    &:active,
                    &:hover{
                        color: $white-text-color;
                        transition: .7s transform;
                        -ms-transform: translate(50px,0); /* IE 9 */
                        -webkit-transform: translate(50px,0); /* Safari */
                        transform: translate(50px,0);
                    }

                    @include breakpoint(tp){
                        padding: 10px 0;
                        font-size: 60px;
                        line-height: 65px;
                    }
                }
            }
        }
    }
}

.submenu{
  
  &__element{
      display: block;

      @include breakpoint(tp){

          display: inline-block;

          &:nth-of-type(n+2){
              margin-left: 15px;
          }
      }

      a {
          &:nth-child(n+2){
              &:before{
                  content : '/';
              }
          }
      }
  }
}

.lang{
    a {
        font-size: 25px;
        line-height: 30px;
        font-weight: $font-weight-thin;
        color: $highlight-red-color;

        &:active,
        &:hover{
            color: $white-text-color;
        }
    }
}

.login{
    a{
        font-size: 16px;
        line-height: 30px;
        font-weight: $font-weight-thin;
        color: $white-text-color;

        &:active,
        &:hover{
            color: $highlight-red-color;
        }
    }

    span{
        margin-right: 15px;
    }
}

.app-logo{
    width: 200px;
    height: 85px;
    line-height: 200px;
    position: relative;
    display: inline-block;

    a{
        @extend .abs-center;
        width: 100%;
        height: inherit;
        line-height: inherit;
        text-indent: 2000em;
        background: url('/images/logo.svg') no-repeat center /contain;
        overflow: hidden;
    }
}
