/**
 * Icons components
 */

.icon {
	&::before {
		font-family: $icon-font;
		font-style: normal;
	}

	@each $modifier, $code in $icons {
		&--#{$modifier}::before {
			content: $code;
		}
	}
}
