.slideshow{
    height: 170px;

    @include breakpoint(tp){
        height: 410px;
    }

    &__content{

        padding: 0 25px;
        box-sizing: border-box;
        outline: none;

        @include breakpoint(tp){

            height: 410px;
        }
    }
}
