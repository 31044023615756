.card{
    height: auto;
    background-color: $dirty-bg-color;
    transition: background-color .3s linear;

    @include breakpoint(tp){
        height: 210px;
    }

    &:hover{
        background-color: $highlight-dark-red-color;

        h4,
        span{
            color: $white-text-color;
        }
    }

    &__image{
        display: none;

        @include breakpoint(tl){
            display: block;
        }
    }
    &__text{
        max-height: 100%;
        overflow: hidden;
        text-align: center;
        padding: 20px 0 40px;
        box-sizing: border-box;
        position: relative;
        transition: color .3s linear;

        @include breakpoint(tp){
            padding: 20px 5px 30px;
            text-align: left;
        }
        span{
            height: 40px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;

            @include breakpoint(tp){
                left: 20px;
                right: inherit;
                margin: inherit;
            }
        }
        h4{
          max-height: 140px;
          min-height: 65px;
          overflow: hidden;

          @include breakpoint(tp){
            font-size: 18px;
            min-height: 145px;
          }
          @include breakpoint(dt){
            font-size: 20px;

          }
        }
    }

    &__image{
        height: 100%;
        @extend .bg-cover;
    }
}

.box-card{
    border: 1px solid $dirty-border-color;
    padding: 30px;

    @include breakpoint(tp,tl){
        padding: 20px 13px;
    }

    &__icon{
        width: 60px;
        margin-right: 10px;
        height: auto;
        display: inline-block;

        @include breakpoint(tl){
            margin-right: 30px;
            width: 80px;
        }

    }

    &__text{
        display: inline-block;
        padding-top: 30px;

        h4{

            @include breakpoint(tp){
                max-height: 60px;
                overflow: hidden;
            }
        }
        p{
            margin-top:10px;
            overflow: hidden;

            @include breakpoint(tp){
                max-height: 100px;
            }
            @include breakpoint(tl){
                max-height: 60px;
            }
        }
    }

}


.teaser-card{
    position: relative;
    padding: 0 20px 10px;
    box-sizing: border-box;
    border: 1px solid $light-border-color;
    cursor: pointer;

    &:hover{
        .teaser-card__image{
            display: block;
        }
    }
    &__title{
        max-height: 60px;
        overflow: hidden;
        transform: translate(0,-15px);
        font-weight: $font-weight-regular;
        color: $black-text-color;

        @include breakpoint(tp){
            transform: translate(0,-20px);
        }
        @include breakpoint(tl){
            transform: translate(0,-25px);
        }
    }
    .link-btn{
        position: initial;
    }

    p{
        min-height: 80px;
        max-height: 80px;
        overflow: hidden;
    }
    &__image{
        display: none;
        position: absolute;
        left: 0;
        right:0;
        top:0;
        bottom:0;
        @extend .bg-cover;
    }
}

.figure-card{
    width: 100%;
    height: 100%;

    &:hover{
        .figure-card__caption{
            color: $highlight-dark-red-color;
        }
        .figure-card__image::before{
            opacity: .7;
        }
    }

    &__image{
        position: relative;
        height: 90%;
        @extend .bg-cover;

        &::before{
            opacity: 0;
            content: '';
            position: absolute;
            top:0;
            bottom:0;
            left:0;
            right:0;
            background-color: $highlight-dark-red-color;
            transition: opacity .3s linear .3s;
        }
    }

    &__caption{
        margin-top: 2%;
        height: 7%;
        transition: color .3s linear .3s;

    }
}

.partners-card{

    &__figure{
        height: 250px;
        position: relative;
        border: 1px solid $dark-border-color;
        padding: 30px;
        box-sizing: border-box;

        @include breakpoint(tl){
            padding: 60px;
        }
    }

    &__image{
        width: 100%;
        height: 100%;
        background-size: contain;
        @extend .bg-cover;
    }
    &__description{
        margin-top: 20px;
    }

}
//user page menu

.menu-card{


    &__link{
        cursor: pointer;
        height: 50px;
        background-color: $light-bg-color;
        padding: 0 15px;
        margin-bottom: 2px;
        transition: background-color .4s;

        a{
            font-family: $font;
            font-size: 20px;
            line-height: 50px;
            color: $text-color;
            display: block;
        }

        &:hover,
        &--active{
            background-color: $highlight-dark-red-color;
            a{
                color: $light-text-color;
            }
        }

    }
}
