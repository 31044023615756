/**
 * Configurations
 *
 * Author: José Osório
 * Copyright 2016
 */

 /**
  * Breakpoints
  */
 $container-widths: (
    pp      : 280px,
    pl      : 400px,
    tp      : 700px,
    tl      : 960px,
    dt      : 1200px
 );

/**
 * Breakpoints
 */
$breakpoints: (
    pp      : (min: 0, max: 440px),
    pl      : (min: 441px, max: 740px),
    tp      : (min: 741px, max: 1000px),
    tl      : (min: 1001px, max: 1240px),
    dt      : (min: 1241px)
);

/**
 * Grid
 */
$grid:(
    cols: 12
);

/**
 * Font families
 */
$primary-font   : Arial, Helvetica, sans-serif !default;

/**
 * Colors
 */
$colors: (
    'black'          : #000000,
    'light-black'    : #222222,
    'light-grey'     : #eeeeee,
    'dark-grey'      : #666666,
    'white'          : #ffffff,
    'dirty-white'    : #f6f6f6
) !default;

/**
 * color applications
 */
$bg-color               : map-get($colors, 'white') !default;
$dirty-bg-color         : map-get($colors, 'dirty-white') !default;
$border-color           : map-get($colors, 'light-grey') !default;
$medium-bg-color        : map-get($colors, 'dark-grey') !default;
$dark-border-color      : map-get($colors, 'light-black') !default;
$text-color             : map-get($colors, 'dark-grey') !default;
$dark-text-color        : map-get($colors, 'light-black') !default;
$highlight-color        : map-get($colors, 'dark-grey') !default;
